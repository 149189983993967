export default {
   'Ganhei um contrato ou licitação': 1,
   'Receber um adiantamento de pagamento': 2,
   'Participar de uma licitação ou concorrência': 3,
   'Tenho uma demanda judicial': 4,
   'Obras e/ou reformas': 11,
   'Prestação de serviços': 12,
   'Fornecimento de bens e/ou materiais': 13,
   'Realizar um depósito recursal trabalhista': 41,
   'Garantir uma execução trabalhista': 42,
   'Tenho crédito ou parcelamento fiscal': 43,
   'Execução Fiscal': 44,
   'Recorrer de uma ação cível': 45,
   'Substituição de depósito recursal': 46,
   'Safra': 100,
   'Equipamentos': 101,
   'RC Geral': 110,
   'D&O': 111,
   "E&O": 112,
   'Riscos Ambientais': 113,
   'RC Eventos': 114,
   'RC Prestação de Serviços em locais de Terceiros': 115,
   'Retenção de Pagamento': 123,
   'Tenho uma demanda aduaneira': 124,
   'Imobiliária': 125,
   'Completion Bond': 126,
   'Manutenção Corretiva': 127,
   'Outros': 131,
   'Outros.': 159,
   'Pessoa Física': 132,
   'Pessoa Jurídica': 133,
   'Edificações': 134,
   'Apartamento': 135,
   'Casas': 136,
   'Conjuntos Comerciais': 137,
   'Obra Civil': 138,
   'Pontes Viadutos': 139,
   'Túneis': 140,
   'Pavimentação': 141,
   'Instalações e Montagens de Equipamentos': 142,
   'Reformas': 143,
   'Aeronáutico': 144,
   'Frota': 145,
   'Individual': 146,
   'Individual.': 153,
   'Seguro Empresarial/ Patrimonial': 147,
   'RD Equipamentos': 148,
   'Construtor Naval': 149,
   'Cibernético': 150,
   'Cibernético.': 161,
   'Operador Portuário': 151,
   'Náutico': 152,
   'Grupo': 154,
   'Avulso': 155,
   'Mensal': 156,
   'Saúde': 157,
   'Odontológico': 158,
   'Residencial': 160,
   'Trânsito aduaneiro': 162,
   'Admissão temporária': 163,
   'Garantir um pagamento de energia': 164,
   'Seguro Fiança Locatícia': 165,
}
